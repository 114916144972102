<template>
    <div class="uk-grid-small" uk-grid>
        <div class="uk-width-1-1">
            <div class="uk-padding-small uk-border-rounded uk-padding-remove-top" style="background: #0275D8;">
                <label for="admin_pic" class="uk-form-label" style="color: #FFFFFF">PIC</label>
                <multiselect
                    id="admin_pic"
                    name="admin_pic"
                    track-by="_id"
                    placeholder="Administrator Job Vacancy (WhatsApp)"
                    label="name"
                    :multiple="false"
                    :options="adminJobVacancyOptions"
                    :options-limit="100"
                    v-model="formData.admin_job_vacancy_id"
                ></multiselect>
            </div>
        </div>
        <div class="uk-width-1-1">
            <label for="title" class="uk-form-label">Nama Pekerjaan <span class="uk-text-danger">*</span></label>
            <input
                id="title"
                name="title"
                type="text"
                minlength="10"
                maxlength="200"
                class="uk-input uk-border-rounded"
                :class="{'uk-form-danger': errors.has('title')}"
                v-model="formData.title"
                v-validate="'required'"
                required
            >
            <!-- <span class="uk-text-small uk-text-danger" v-show="errors.has('title')">{{ errors.first('title') }}</span> -->
            <div class="uk-flex" :class="[errors.has('title') ? 'uk-flex-between' : 'uk-flex-right']">
                <span class="uk-text-small uk-text-danger" v-show="errors.has('title')">{{ errors.first('title') }}</span>
                <span>{{ vacancyTitleLength }}/200</span>
            </div>
        </div>
        <div class="uk-width-1-1">
            <label
                for="job_preference"
                class="uk-form-label"
            >
                Related Job Preference(s) <span class="uk-text-danger">*</span>
            </label>
            <multiselect
                id="job_preference"
                name="job_preference"
                track-by="_id"
                placeholder="Select job preference"
                label="name"
                :multiple="false"
                :options="jobPreferenceOptions"
                :options-limit="100"
                v-model="formData.job_preference_id"
                v-validate="'required'"
                required
            ></multiselect>
            <span
                v-show="errors.has('job_preference')"
                class="uk-text-small uk-text-danger"
            >
                {{ errors.first('job_preference') }}
            </span>
        </div>
        <div v-if="selectedData" class="uk-width-1-1">
            <label for="jobRole" class="uk-form-label">Job role</label>
            <input
                id="jobRole"
                type="text"
                class="uk-input uk-border-rounded"
                :value="selectedData.role"
                disabled
            >
        </div>
        <div v-if="selectedData" class="uk-width-1-1">
            <label for="company" class="uk-form-label">Company</label>
            <input
                id="company"
                type="text"
                class="uk-input uk-border-rounded"
                :value="selectedData.company"
                disabled
            >
        </div>
        <div v-if="selectedData" class="uk-width-1-1">
            <label for="office" class="uk-form-label">Office</label>
            <input
                id="office"
                type="text"
                class="uk-input uk-border-rounded"
                :value="selectedData.office"
                disabled
            >
        </div>
        <div class="uk-width-1-1">
            <label for="jobDescription" class="uk-form-label">Deskripsi Pekerjaan <span class="uk-text-danger">*</span></label>
            <textarea
                id="jobDescription"
                name="desc"
                rows="5"
                minlength="70"
                maxlength="320"
                class="uk-textarea uk-border-rounded"
                :class="{'uk-form-danger': errors.has('desc')}"
                v-model="formData.job_description"
                v-validate="'required'"
                required
            ></textarea>
            <span class="uk-text-small uk-text-danger" v-show="errors.has('desc')">{{ errors.first('desc') }}</span>
        </div>
        <div class="uk-width-1-2">
            <label for="salary" class="uk-form-label">Fee <span class="uk-text-danger">*</span></label>
            <input
                id="salary"
                name="salary"
                type="number"
                class="uk-input uk-border-rounded"
                :class="{'uk-form-danger': errors.has('salary')}"
                min=0
                step=500
                v-model="formData.salary"
                v-validate="'required'"
                required
                disabled
            >
            <span class="uk-text-small uk-text-danger" v-show="errors.has('salary')">{{ errors.first('salary') }}</span>
        </div>
        <div class="uk-width-1-2">
            <label for="salaryUnit" class="uk-form-label">Fee Unit <span class="uk-text-danger">*</span></label>
            <select
                id="salaryUnit"
                name="salary_unit"
                class="uk-select uk-border-rounded"
                :class="{'uk-form-danger': errors.has('salary_unit')}"
                v-model="formData.salary_unit"
                v-validate="'required'"
                disabled
                required
            >
                <option value="" disabled selected>Select Fee Unit</option>
                <option value="Jam">Jam</option>
                <option value="Hari">Hari</option>
                <option value="Bulan">Bulan</option>
            </select>
            <span
                class="uk-text-small uk-text-danger"
                v-show="errors.has('salary_unit')"
            >
                {{ errors.first('salary_unit') }}
            </span>
        </div>
        <div class="uk-width-1-1">
            <label for="feeOvertimeHourly" class="uk-form-label">Fee Overtime Hourly <span
                class="uk-text-danger">*</span></label>
            <input
                id="feeOvertimeHourly"
                name="salary_ot"
                type="number"
                min=0
                step=500
                class="uk-input uk-border-rounded"
                :class="{'uk-form-danger': errors.has('salary_ot')}"
                v-model="formData.salary_overtime_hourly"
                v-validate="'required'"
                required
                disabled
            >
            <span
                v-show="errors.has('salary_ot')"
                class="uk-text-small uk-text-danger"
            >
                {{ errors.first('salary_ot') }}
            </span>
        </div>
        <div class="uk-width-1-1">
            <label
                for="vacancyStatusFilter"
                class="uk-form-label"
            >
                Vacancy Status Filter <span class="uk-text-danger">*</span>
            </label>
            <select
                id="vacancyStatusFilter"
                name="vacancy_status_filter"
                class="uk-select uk-border-rounded"
                :class="{'uk-form-danger': errors.has('vacancy_status_filter')}"
                v-model="formData.job_type_id"
                v-validate="'required'"
                required
            >
                <option value="" disabled selected>Select Vacancy Status Filter</option>
                <option
                    v-for="(item, index) in jobTypeOptions"
                    :key="index"
                    :value="item._id"
                >{{ item.name || '-' }}
                </option>
            </select>
            <span
                v-show="errors.has('vacancy_status_filter')"
                class="uk-text-small uk-text-danger"
            >
                {{ errors.first('vacancy_status_filter') }}
            </span>
        </div>
        <div class="uk-width-1-1">
            <label for="contract" class="uk-form-label">Contract <span class="uk-text-danger">*</span></label>
            <multiselect
                id="contract"
                name="contract"
                track-by="_id"
                placeholder="Select Contract"
                label="title"
                :multiple="true"
                :options="selectableContractOptions"
                v-model="formData.job_contracts"
                @remove="onRemoveContractOptions($event)"
                @open="onOpenContractOptions"
                v-validate="'required'"
                required
            ></multiselect>
            <span
                v-show="errors.has('contract')"
                class="uk-text-small uk-text-danger"
            >
                {{ errors.first('contract') }}
            </span>
        </div>
        <div class="uk-width-1-1">
            <label for="benefits" class="uk-form-label">Benefit tambahan (Optional)</label>
            <input
                id="benefits"
                name="Benefit"
                type="text"
                class="uk-input uk-border-rounded"
                v-model="formData.benefits"
            >
        </div>
        <div class="uk-width-1-1">
            <label for="startDate" class="uk-form-label">Mulai Bekerja <span class="uk-text-danger">*</span></label>
            <div class="uk-width-1-1 uk-inline">
                <datetime
                    id="startDate"
                    name="Start Date"
                    input-class="uk-input uk-border-rounded"
                    type="date"
                    v-model="formData.job_start"
                    disabled
                ></datetime>
                <span class="uk-form-icon uk-form-icon-flip" uk-icon="icon: calendar"></span>
            </div>
        </div>
        <div class="uk-width-1-1">
            <label :for="'workHourStart' && 'workHourEnd'" class="uk-form-label">Jam Kerja (Optional)</label>
            <div class="uk-width-1-1 uk-grid-small uk-padding-remove uk-margin-remove" uk-grid>
                <div class="uk-width-1-3 uk-padding-remove uk-margin-remove">
                    <div class="uk-inline uk-width-expand">
                        <datetime
                            id="workHourStart"
                            name="work_hour_start"
                            input-class="uk-input uk-border-rounded"
                            type="time"
                            v-model="formData.working_hour_start"
                            :max-datetime="formData.working_hour_end"
                            placeholder="HH:MM"
                        ></datetime>
                        <span class="uk-form-icon uk-form-icon-flip" uk-icon="icon: clock"></span>
                    </div>
                </div>
                <div class="uk-width-1-3 uk-padding-remove uk-margin-remove uk-flex uk-flex-middle uk-flex-center">
                    <span class="uk-text-bold"> - </span>
                </div>
                <div class="uk-width-1-3 uk-padding-remove uk-margin-remove">
                    <div class="uk-inline uk-width-expand">
                        <datetime
                            id="workHourEnd"
                            name="working_hour_end"
                            input-class="uk-input uk-border-rounded"
                            type="time"
                            v-model="formData.working_hour_end"
                            :min-datetime="formData.working_hour_start"
                            :disabled="!formData.working_hour_start"
                            placeholder="HH:MM"
                        ></datetime>
                        <span class="uk-form-icon uk-form-icon-flip" uk-icon="icon: clock"></span>
                    </div>
                </div>
            </div>
        </div>

        <div class="uk-width-1-2">
            <label for="gender" class="uk-form-label">Gender <span class="uk-text-danger">*</span></label>
            <select
                id="gender"
                name="gender"
                class="uk-select uk-border-rounded"
                v-model="formData.gender"
            >
                <option
                    v-for="(item, index) in genderOptions"
                    :key="index"
                    :value="item.value"
                >{{ item.label || '-' }}</option>
            </select>
        </div>
        <div class="uk-width-1-2">
            <label
                class="uk-form-label"
                :for="'minAge' && 'maxAge'"
            >
                <input class="uk-checkbox" type="checkbox" v-model="ageFilter.needed">
                Batasan Usia
            </label>
            <div v-if="ageFilter.needed" class="uk-width-1-1 uk-grid-small uk-padding-remove uk-margin-remove" uk-grid>
                <div class="uk-width-1-3 uk-padding-remove uk-margin-remove">
                    <input
                        id="minAge"
                        name="age_min"
                        type="number"
                        class="uk-input uk-border-rounded"
                        :class="{'uk-form-danger': errors.has('age_min')}"
                        min=16
                        v-model="ageFilter.min"
                        v-validate="'required'"
                        required
                    >
                    <span
                        class="uk-text-small uk-text-danger"
                        v-show="errors.has('age_min')"
                    >
                        {{ errors.first('age_min') }}
                    </span>
                </div>
                <div class="uk-width-1-3 uk-padding-remove uk-margin-remove uk-flex uk-flex-middle uk-flex-center">
                    <span class="uk-text-bold"> - </span>
                </div>
                <div class="uk-width-1-3 uk-padding-remove uk-margin-remove">
                    <input
                        id="maxAge"
                        name="age_max"
                        type="number"
                        class="uk-input uk-border-rounded"
                        :class="{'uk-form-danger': errors.has('age_max')}"
                        :min="ageFilter.min"
                        max=61
                        v-model="ageFilter.max"
                        v-validate="'required'"
                        required
                    >
                    <span
                        class="uk-text-small uk-text-danger"
                        v-show="errors.has('age_max')"
                    >
                        {{ errors.first('age_max') }}
                    </span>
                </div>
            </div>
        </div>
        <div class="uk-width-1-1">
            <label for="minEducation" class="uk-form-label">Pendidikan Minimal <span class="uk-text-danger">*</span></label>
            <select
                id="education"
                name="education"
                class="uk-select uk-border-rounded"
                :class="{'uk-form-danger': errors.has('education')}"
                v-model="formData.education_level_id"
                v-validate="'required'"
                required
            >
                <option value="" disabled selected>Pilih Pendidikan Minimal</option>
                <option
                    v-for="(item, index) in educationLevelOptions"
                    :key="index"
                    :value="item._id"
                >{{ item.name || '-' }}
                </option>
            </select>
            <span
                v-show="errors.has('education')"
                class="uk-text-small uk-text-danger"
            >
                {{ errors.first('education') }}
            </span>
        </div>
        <div class="uk-width-1-1">
            <label for="skillNeeded" class="uk-form-label">Keahlian yang Diperlukan <span
                class="uk-text-danger">*</span></label>
            <input
                id="skillNeeded"
                name="skills"
                type="text"
                class="uk-input uk-border-rounded"
                :class="{'uk-form-danger': errors.has('skills')}"
                v-model="formData.skill_needed"
                v-validate="'required'"
                required
            >
            <span
                class="uk-text-small uk-text-danger"
                v-show="errors.has('skills')"
            >
                {{ errors.first('skills') }}
            </span>
        </div>
        <div class="uk-width-1-1">
            <label class="uk-form-label" for="maxAge">
                <input
                    class="uk-checkbox" type="checkbox"
                    v-model="formData.is_experience_needed"
                >
                Butuh Pengalaman kerja
            </label>
        </div>
        <div class="uk-width-1-1">
            <label for="requiredDocs" class="uk-form-label">Required Docs</label>
            <multiselect
                id="requiredDocs"
                name="Required Docs"
                placeholder="Select Docs to be Required"
                label="label"
                track-by="value"
                :multiple="true"
                :options="docOptions"
                v-model="formData.required_docs"
            >
                <template v-slot:tag="{ option }">
                    <span v-if="option.label === 'KTP'">
                        <i class="multiselect__tag" style="padding-right: 12px; font-style: normal">{{ option.label }}</i>
                    </span>
                </template>
            </multiselect>
        </div>
        <div class="uk-width-1-1">
            <label for="additionalDocs" class="uk-form-label">Additional Docs</label>
            <multiselect
                id="additionalDocs"
                name="Additional Docs"
                placeholder="Select Additional Docs"
                label="label"
                track-by="value"
                :multiple="true"
                :options="additionalDocOptions"
                v-model="formData.additional_docs"
            ></multiselect>
        </div>
        <div class="uk-width-1-1">
            <label for="requiredTest" class="uk-form-label">Required Test</label>
            <multiselect
                id="requiredTest"
                name="Required Test"
                v-model="formData.required_tests"
                placeholder="Select Required Test"
                label="name"
                track-by="_id"
                :multiple="true"
                :options="testCategoryOptions"
            ></multiselect>
        </div>
        <template v-if="formData.required_tests.length > 0">
            <div
                v-for="(category, index) in formData.required_tests"
                :key="index"
                class="uk-width-1-1"
            >
                <label
                    for="minimumScore"
                    class="uk-form-label"
                    :key="category.name"
                >
                    {{ category.name || '-' }} Minimum Score <span class="uk-text-danger">*</span>
                </label>
                <input
                    :key="category._id"
                    id="minimumScore"
                    name="minimum_score"
                    class="uk-input uk-border-rounded"
                    type="number"
                    v-model="category.minimum_score"
                    max="100"
                    min="0"
                    required
                >
            </div>
        </template>
    </div>
</template>

<script>
import moment from 'moment';
import {Datetime} from 'vue-datetime';
import Multiselect from 'vue-multiselect';
import formatter from '@/utils/formatter';

moment.locale('id');

export default {
    props: {
        jobTypeOptions: {
            type: Array,
            required: true,
        },
        contractOptions: {
            type: Array,
            required: true,
        },
        jobPreferenceOptions: {
            type: Array,
            required: true,
        },
        ageFilter: {
            type: Object,
            required: true,
        },
        genderOptions: {
            type: Array,
            required: true,
        },
        educationLevelOptions: {
            type: Array,
            required: true,
        },
        docOptions: {
            type: Array,
            required: true,
        },
        additionalDocOptions: {
            type: Array,
            required: true,
        },
        testCategoryOptions: {
            type: Array,
            required: true,
        },
        formData: {
            type: Object,
            required: true,
        },
        selectedData: {
            type: Object,
            default: () => {
            },
        },
        employerCompanyId: {
            type: String,
            required: true,
        },
        adminJobVacancyOptions: {
            type: Array,
            required: true,
        },
    },
    components: {
        Multiselect,
        Datetime,
    },
    data() {
        return {
            isLoading: false,
            formatter,
            selectableContractOptions: this.contractOptions,
        };
    },
    computed: {
        vacancyTitleLength() {
            return this.formData?.title?.length || 0;
        }
    },
    mounted() {
        this.validate();
    },
    watch: {
        '$props.formData': {
            deep: true,
            async handler() {
                this.validate();
            },
        },
    },
    methods: {
        async validate() {
            const validate = await this.$validator.validateAll();
            if (!validate || this.$validator.errors.any()) {
                this.$emit('inValid');
            } else {
                this.$emit('valid');
            }
        },
        onRemoveContractOptions(eventObject) {
            // Remove drafted contract / not this employer company's contract when remove an item in multiselect contract form
            if (eventObject.status == 1 || (eventObject.company_id !== this.employerCompanyId && eventObject.type == 2)) {
                this.selectableContractOptions = this.selectableContractOptions.filter((obj) => obj._id !== eventObject._id);
            }
        },
        onOpenContractOptions() {
            // Remove drafted contract / not this employer company's contract when open multiselect contract form
            for (const contract of this.contractOptions) {
                if (contract.status == 1 || (contract.company_id !== this.employerCompanyId && contract.type == 2)) {
                    const exist = this.formData.job_contracts.find((object) => object._id == contract._id);
                    if (!exist) {
                        this.selectableContractOptions = this.selectableContractOptions.filter((obj) => obj._id !== contract._id);
                    }
                }
            }
        },
    },
};
</script>
